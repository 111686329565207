<template>
  <b-card-code>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
      <div class="custom-search d-flex justify-content-start">
          <vue-excel-xlsx
              :data="getData"
              :columns="columns10"
              :filename="'filename'"
              :sheetname="'subjects'"
          >
              <b>Excel</b>
          </vue-excel-xlsx>
          <vue-json-to-csv :json-data="getData"
                           :csv-title="'My_CSV'"
          >
              <button>
                  <b>CSV</b>
              </button>
          </vue-json-to-csv>
          <button @click="pdfGenerate()">
              <b>PDF</b>
          </button>
      </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="results"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: index -->
        <span v-else-if="props.column.field === 'classes_id'">
          {{ classByID(props.row.classes_id) }}
        </span>
        <!-- <span v-else-if="props.column.field === 'version_id'">
          {{ versionByID(props.row.version_id) }}
        </span>
        <span v-else-if="props.column.field === 'shift_id'">
          {{ shiftByID(props.row.shift_id) }}
        </span> -->
        <span v-else-if="props.column.field === 'wing_id'">
          {{ wingByID(props.row.wing_id) }}
        </span>
        <span v-else-if="props.column.field === 'student_group_id'">
          {{ student_groupByID(props.row.student_group_id) }}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academic_yearByID(props.row.academic_year_id) }}
        </span>
        <span v-else-if="props.column.field === 'exam_type_id'">
          {{ examTypeByID(props.row.exam_type_id) }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button :variant="props.row.is_published?'danger':'primary'" @click="statusChange(props.row.id)">
                {{ props.row.is_published?'Unpublish':'Publish' }}
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['5','10','15']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BPagination, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";

export default {
  name:'ResultPublish',
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Class',
          field: 'classes_id',
        },
        // {
        //   label: 'Version',
        //   field: 'version_id',
        // },
        // {
        //   label: 'Shift',
        //   field: 'shift_id',
        // },
        {
          label: 'Wing',
          field: 'wing_id',
        },
        {
          label: 'Student Group',
          field: 'student_group_id',
        },
        {
          label: 'Academic Year',
          field: 'academic_year_id',
        },
        {
          label: 'Exam Name',
          field: 'exam_type_id',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      results:[],
        columns10: [
            {
                label: 'Class',
                field: 'ClassName',
            },
            // {
            //     label: 'Version',
            //     field: 'Version',
            // },
            // {
            //     label: 'Shift',
            //     field: 'Shift',
            // },
            {
                label: 'Wing',
                field: 'Wing',
            },
            {
                label: 'Student Group',
                field: 'StudentGroup',
            },
            {
                label: 'Academic Year',
                field: 'AcademicYear',
            },
            {
                label: 'Exam Name',
                field: 'ExamName',
            },
            {
                label: 'Status',
                field: 'Status',
            },
        ],
    }
  },
  methods:{
    statusChange(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to change status!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/result/publish/status/change${id}`).then((response)=>{
            this.getProcessResult();
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    async getProcessResult(){
      await apiCall.get('/get/all/process/result').then((response)=>{
        this.results=response.data;
      }).catch(()=>{
        this.$toaster.error('Invalid request');
      })
    },
      pdfGenerate(){
          /*only change able*/
          let title="Result Publish list";
          let clm=['ClassName','Version','Shift','Wing','StudentGroup','AcademicYear','ExamName','Status'];
          /*change able end*/
          let data =new FormData();
          data.append('title',title);
          data.append('columns',JSON.stringify(clm));
          data.append('data',JSON.stringify(this.getData));
          apiCall.post('/get/common/table/export/pdf',data,{responseType: 'blob'}).then((response)=>{
              const content = response.headers['content-type'];
              download(response.data,'subject.pdf', content)
          }).catch((error) => {
              this.$toaster.error(error.response.data.message);
          });
      },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['classes','classByID','versions','versionByID','shifts','shiftByID','wings','wingByID',
    'student_groups','student_groupByID','academic_years','academic_yearByID','exam_types','examTypeByID']),
      getData(){
          return this.results.map(item => ({
              ClassName: item.classes_id ? this.classByID(item.classes_id): '',
              Version: item.version_id ? this.versionByID(item.version_id) :'',
              Shift: item.shift_id ? this.shiftByID(item.shift_id): '',
              Wing: item.wing_id ? this.shiftByID(item.wing_id): '',
              StudentGroup: item.student_group_id ? this.student_groupByID(item.student_group_id) : '',
              AcademicYear: item.academic_year_id ? this.academic_yearByID(item.academic_year_id) : '',
              ExamName: item.exam_type_id ? this.examTypeByID(item.exam_type_id) : '',
              Status: item.is_published ? 'Published' : 'Unpublish'
          }));
      }
  },
  created() {
    this.getProcessResult();
    if(this.classes.length <1) this.$store.dispatch('GET_ALL_CLASSES');
    if(this.versions.length <1) this.$store.dispatch('GET_ALL_VERSION');
    if(this.shifts.length <1) this.$store.dispatch('GET_ALL_SHIFT');
    if(this.wings.length <1) this.$store.dispatch('GET_ALL_WING');
    if(this.student_groups.length <1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if(this.exam_types.length <1) this.$store.dispatch('GET_ALL_EXAM_TYPE');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>